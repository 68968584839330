// dependencies.
import React from 'react'
// components.
import PlatformDropdown, { platforms } from 'src/components/button/Download/PlatformDropdown'

// Styles & Images:
import 'src/components/pages/shared/scss/DownloadPlatformsSection.scss'

// Main component:
const DownloadPlatformsSection = ({ location, asset, colors, copy }) => {
  const checkColorsOverride =
    colors && colors.gradient && colors.gradient.length > 0
      ? colors.gradient
      : asset.colors.gradient

  // Download links.
  const DOWNLOAD_LINKS_ENABLED = asset.desktopWallet || asset.mobileWallet || asset.web3Wallet

  const downloadDropdownProps = {
    colors: checkColorsOverride,
    trackerPage: `${asset.name}Page`,
    placement: 'top',
    location,
  }

  const googleDownloadProps = {
    utmSource: 'exodus-website',
    utmCampaign: `${asset.ticker.toLowerCase()}-wallet`,
    utmContent: `${asset.ticker.toLowerCase()}-wallet`,
  }

  return (
    <section className="x__section-download">
      <div className="x__section-download__content">
        <h2 className="x__section-download__heading" dangerouslySetInnerHTML={{ __html: copy }} />

        {DOWNLOAD_LINKS_ENABLED && (
          <div className="x__section-download__actions">
            {asset.desktopWallet && (
              <PlatformDropdown platform={platforms.desktop} {...downloadDropdownProps} />
            )}
            {asset.mobileWallet && (
              <PlatformDropdown
                platform={platforms.mobile}
                androidLinkProps={googleDownloadProps}
                {...downloadDropdownProps}
              />
            )}
            {asset.web3Wallet && (
              <PlatformDropdown
                platform={platforms.web3}
                extensionLinkProps={googleDownloadProps}
                {...downloadDropdownProps}
              />
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default DownloadPlatformsSection
