import React, { forwardRef, useMemo } from 'react'

import AssetIcon from 'components/assets/shared/AssetIcon'
import Button from 'components/button'
import Carousel from 'components/carousel/AppsCarousel'
import useFetchSimplifiedAssets from 'js/assets/hooks/useFetchSimplifiedAssets'
import AnimatedNumber from 'js/utils/animate-numbers'
import useDimensions from 'js/utils/hooks/useDimensions'
import { ASSETS_PAGE_PATH } from 'src/constants'

// Styles & Images:
import 'components/pages/asset-page/scss/SupportedAssetsSection.scss'

// helpers:
const assetList = [
  'TRX',
  'RNDR',
  'OP',
  'UMA',
  'BAT',
  'REPv1',
  'AUDIO',
  'AMP',
  'BRD',
  'XMR',
  'TFUEL',
  'BTC',
  'DAI',
  'ZEC',
  'BNB',
  'DOGE',

  'SUSHI',
  'mSOL',
  'WETH',
  'LPT',
  'BCH',
  'ETC',
  'NEO',
  'REQ',
  'RUNE',
  'ANTv1',
  'THETA',
  'LOOM',
  'ZIL',
  'USDT',
  'HOT',
  'GENE',
  'IMX',

  'AXS',
  'YFI',
  'APE',
  'GLM',
  'TUSD',
  'ANKR',
  'LRC',
  'BAL',
  'GRT',
  'BTG',
  'ADA',
  'EGLD',
  'LINK',
  'USDC',
  'XRP',
  'ONT',
  'FIL',
  'HT',

  'SAND',
  'COTI',
  'DASH',
  'XNO',
  'AST',
  'ENS',
  'DCR',
  'PLR',
  'RAY',
  'FTM',
  'CAKE',
  'AAVE',
  'VET',
  'ENJ',
  'SUPER',
  'BAND',
  'STMX',
  'MATIC',
  'VGX',

  'ILV',
  'ATOM',
  'EOS',
  'OHM',
  'LTC',
  'ZRX',
  'BTT',
  'FXS',
  'FRAX',
  'COMP',
  'REP',
  'POLIS',
  'API3',
  'COPE',
  'OMG',
  'SOL',
  'SX',
  'YGG',

  'EXIT',
  'ALCX',
  'VRA',
  'CND',
  'GALA',
  'HBAR',
  'REN',
  'DENT',
  'QNT',
  'ATLAS',
  'ALGO',
  'NEAR',
  'ETH',
  'XLM',
  'MTL',
  'NMR',
  '1INCH',

  'XTZ',
  'RDNT',
  'ANT',
  'stSOL',
  'stETH',
  'INJ',
  'PERP',
  'SMILE',
  'SRM',
  'TULIP',
  'ANC',
  'LEO',
  'CHZ',
  'FUN',
  'UNI',
  'DOT',
]

const itemsPerRow = [16, 17, 18, 19, 18, 17, 16]

const selectRandomItems = (arrays, count) => {
  const selectedItems = []

  for (const arr of arrays) {
    if (arr.length > 0) {
      const randomIndex = Math.floor(Math.random() * arr.length)

      selectedItems.push(arr[randomIndex])
      arr.splice(randomIndex, 1)
    }
  }

  return selectedItems.slice(0, count)
}

const getAssetObjects = (assets, selectedList) => {
  // filter assets.
  const filteredAssets = assets.filter(({ ticker }) => selectedList.includes(ticker))

  // map keys in selectedList.
  const keyToIndexMap = {}
  selectedList.forEach((key, index) => {
    keyToIndexMap[key] = index
  })

  // order filteredAssets.
  const sortedAssets = [...filteredAssets].sort((a, b) => {
    const indexA = keyToIndexMap[a.ticker]
    const indexB = keyToIndexMap[b.ticker]
    return indexA - indexB
  })

  // delete duplicates.
  const uniqueAssets = []
  const seenKeys = new Set()

  sortedAssets.forEach((asset) => {
    if (!seenKeys.has(asset.ticker)) {
      uniqueAssets.push(asset)
      seenKeys.add(asset.ticker)
    }
  })

  return uniqueAssets
}

// Partials:
const AssetIcons = ({ assets, grid, isMobile }) => {
  let startIndex = 0

  const rows = grid.map((rowCount, rowIndex) => {
    const endIndex = startIndex + rowCount

    const rowItems = assets
      .slice(startIndex, endIndex)
      .map(({ icon, name, ticker }, i) => (
        <AssetIcon
          key={`${name}-${ticker}-${i}`}
          icon={icon}
          name={name}
          ticker={ticker}
          size="100"
        />
      ))

    startIndex = endIndex

    return rowItems
  })

  if (isMobile) {
    return (
      <Carousel id="x__asset-icons__mobile-carousel" disableArrows>
        {Array.from({ length: 3 }, (_, i) => selectRandomItems(rows, 5))}
      </Carousel>
    )
  }

  return (
    <div className="x__asset-icons">
      {rows.map((content, i) => (
        <div key={i} className="x__asset-icons__row">
          {content}
        </div>
      ))}
    </div>
  )
}

// Main component:
const SupportedAssetsSection = ({ data, showCount = false }, ref) => {
  const { isMobile } = useDimensions()
  const { assets, count } = useFetchSimplifiedAssets()

  const selectedAssets = useMemo(() => {
    return getAssetObjects(assets, assetList)
  }, [assets, assetList])

  const animatedNumberValue = {
    value: showCount ? count.all : data.count,
    duration: showCount ? 1000 : 2000,
  }

  if (selectedAssets.length <= 0) return null

  return (
    <section className="x__section-supported-assets" ref={ref}>
      <div className="x__section-supported-assets__content">
        <h2>
          <span>
            <AnimatedNumber
              {...animatedNumberValue}
              formatValue={(value) => value.toLocaleString()}
            />
            +
          </span>
          {data.h2}
        </h2>
        <Button to={ASSETS_PAGE_PATH} copy={data.cta} size="largest" />
      </div>

      <AssetIcons assets={selectedAssets} grid={itemsPerRow} isMobile={isMobile} />

      <Button type="secondary" to={ASSETS_PAGE_PATH} copy={data.cta} size="largest" />
    </section>
  )
}

export default forwardRef(SupportedAssetsSection)
